<template>
  <div class="empty-coupon">
    <div class="empty-content">
      <img src="./empty.svg" alt="empty list" width="160" height="90" />
      <p class="empty-text">
        {{ t('coupon.emptyCouponPlaceholder') }}
      </p>
    </div>
    <div class="empty-actions">
      <StButton
        block
        type="gray"
        :label="t('coupon.goToMyBets')"
        :to="myBetsLink"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '@st/user/stores/useUserStore'
import type { RouteLocationRaw } from 'vue-router'

const { t } = useI18n()
const { isAuthenticated } = storeToRefs(useUserStore())

const myBetsLink = computed<RouteLocationRaw>(() =>
  isAuthenticated.value ? '/personal/bets' : { query: { modal: 'login' } },
)
</script>

<style scoped>
.empty-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200, 16px);
  align-items: center;
  justify-content: center;

  padding: var(--spacing-450) var(--spacing-150) var(--spacing-250)
    var(--spacing-150);
}

.empty-text {
  margin: 0;
  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary);
}

.empty-actions {
  display: flex;
  gap: var(--spacing-100);
  justify-content: center;
  padding: var(--spacing-150);
}
</style>
