<template>
  <div class="ordinary-item">
    <div v-if="!outcome.isActive" class="disabled-overlay">
      {{ t('coupon.outcomeNotAvailable') }}
    </div>
    <button
      class="remove-button"
      type="button"
      @click="removeOutcome(outcome.uniqId)"
    >
      <StIcon name="cross-large" :size="mobile ? 20 : 16" />
    </button>
    <div class="ordinary-content">
      <OutcomeDetails :outcome="outcome" />
      <BetAmountInput
        class="amount-input"
        type="ordinary"
        :model-value="props.amount"
        :max-amount="props.maxAmount"
        :currency-icon="currencyIcon"
        :freebet="props.freebet"
        :error="!!error"
        :error-message="error"
        :show-max-button="showMaxButton"
        @update:model-value="emit('update:amount', $event)"
      />
      <FreebetsSelect
        v-if="notUsedFreebets.length"
        class="freebets-select"
        :model-value="freebet"
        :bet-rate="outcome.outcome.odds.toString()"
        bet-type="ordinary"
        :already-selected-freebets="alreadySelectedFreebets"
        @update:model-value="emit('update:freebet', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '@st/ui/components/StIcon/types'
import type { Freebet } from '@st/bonuses/types'
import { useFreebetsStore } from '@st/bonuses/stores/useFreebetsStore'
import {
  type ExtendedCouponOutcome,
  useCouponStore,
} from '../../../stores/useCouponStore'

const { t } = useI18n()
const { removeOutcome } = useCouponStore()
const { notUsedFreebets } = storeToRefs(useFreebetsStore())

const props = defineProps<{
  outcome: ExtendedCouponOutcome
  amount: string
  freebet?: Freebet
  maxAmount: string
  currencyIcon: IconName
  error?: string
  showMaxButton?: boolean
  mobile?: boolean
  alreadySelectedFreebets?: Freebet[]
}>()

const emit = defineEmits<{
  (e: 'update:amount', value: string): void
  (e: 'update:freebet', value?: Freebet): void
}>()
</script>

<style scoped>
.ordinary-item {
  position: relative;
  display: flex;
  background: var(--background-primary);
  border-radius: var(--border-radius-100);
}

.freebets-select {
  margin-top: var(--spacing-100);
  margin-bottom: var(--spacing-025);
}

.disabled-overlay {
  content: '';

  position: absolute;
  z-index: 1;
  inset: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  padding-bottom: var(--spacing-050);

  font: var(--desktop-text-sm-semibold);
  color: var(--text-secondary);

  background-color: rgba(17 17 24 / 60%);
  backdrop-filter: blur(1px);
  border-radius: var(--border-radius-100);
}

.ordinary-content {
  flex-grow: 1;
  padding: var(--spacing-075) var(--spacing-100) var(--spacing-100) 0;
}

.amount-input {
  margin-top: var(--spacing-100);
}

.remove-button {
  all: unset;

  cursor: pointer;

  position: relative;
  z-index: 2;

  padding: var(--spacing-075);

  color: var(--text-tertiary);

  &:hover {
    color: var(--text-primary);
  }

  &:active {
    color: var(--text-secondary);
  }
}
</style>
