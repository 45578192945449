<template>
  <div class="summary">
    <div class="summary-row">
      <span class="summary-label">
        {{ t('coupon.expressTotalCoefficient') }}
      </span>
      <span class="summary-rate">
        {{ totalRate }}
      </span>
    </div>
    <div class="summary-row">
      <span class="summary-label">
        {{ t('coupon.expressTotalPayoff') }}
      </span>
      <span class="summary-payoff">
        {{ format(totalPayoff) }}
        <StIcon :size="16" :name="currencyIcon" />
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
import type { IconName } from '@st/ui/components/StIcon/types'
import { truncRate } from '@st/utils'
import type { ExtendedCouponOutcome } from '../../../stores/useCouponStore'

const { t } = useI18n()
const { format } = useCryptoFormatter()

const props = defineProps<{
  outcomes: ExtendedCouponOutcome[]
  amount: string
  currencyIcon: IconName
}>()

const totalRate = computed(() => {
  const decimalRate = props.outcomes.reduce(
    (acc, outcome) => acc.times(outcome.outcome.odds),
    new Decimal(1),
  )

  return truncRate(decimalRate.toNumber())
})

const totalPayoff = computed(() =>
  new Decimal(props.amount || '0').times(totalRate.value).toString(),
)
</script>

<style scoped>
.summary {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
  padding: var(--spacing-150) var(--spacing-100);
}

.summary-row {
  display: flex;
  justify-content: space-between;
}

.summary-label {
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.summary-rate {
  font-family: var(--sf-pro-display);
  /* stylelint-disable */
  font-size: 14px;
  font-weight: 800;
  font-style: italic;
  line-height: 18px; /* 128.571% */
  color: var(--text-primary);
  letter-spacing: 0.28px;
  /* stylelint-enable */
}

.summary-payoff {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
  font: var(--desktop-text-sm-semibold);
}
</style>
