import type { IconName } from '@st/ui/components/StIcon/types'
import { useUserStore } from '@st/user/stores/useUserStore'
import type { RouteLocationRaw } from 'vue-router'

export function useCasinoMenu() {
  const casinoStore = useCasinoStore()
  const { t } = useI18n()
  const { categories } = storeToRefs(casinoStore)
  const { isAuthenticated } = storeToRefs(useUserStore())

  const topCategories = computed(() =>
    categories.value.filter(
      (category) => category.isMain && category.visibleForLeftMenu,
    ),
  )

  const bottomCategories = computed(() =>
    categories.value.filter(
      (category) => !category.isMain && category.visibleForLeftMenu,
    ),
  )

  const recentlyGames = {
    code: 'recently',
    name: t('casino.recentGames'),
    iconName: 'circle-clock-solid' as IconName,
    link: '/casino/recent' as RouteLocationRaw,
  }

  const extendedTopCategories = computed<any>(() =>
    isAuthenticated.value
      ? [...topCategories.value, recentlyGames]
      : topCategories.value,
  )

  return { extendedTopCategories, bottomCategories }
}
